import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import cricketAnim from "./assets/common/cricket-bat-ball-anim.json";

// code imports
import React, { useMemo, Suspense, useEffect } from 'react';
import lottie from "lottie-web/build/player/lottie_light";
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import RedirectingPage from "./components/common/RedirectingPage";

// lazy loading pages
const AdminPanel = React.lazy(() => import('./components/Pages/AdminPanel/AdminPanel'));

const Home = React.lazy(() => import('./components/Pages/Home/Home'));
/*
const SignUp = React.lazy(() => import('./components/Pages/SignUp/SignUp'));
const LogIn = React.lazy(() => import('./components/Pages/LogIn/LogIn'));
const Dashboard = React.lazy(() => import("./components/Pages/Dashboard/Dashboard"));
const LinkWallet = React.lazy(() => import('./components/Pages/LinkWallet/LinkWallet'));
const Marketplace = React.lazy(() => import('./components/Pages/Marketplace/Marketplace'));
const ItemView = React.lazy(() => import('./components/Pages/ItemView/ItemView'));
const ModelView = React.lazy(() => import('./components/Pages/ModelView/ModelView'));
const Wallet = React.lazy(() => import('./components/Pages/Wallet/Wallet'));
const Profile = React.lazy(() => import('./components/Pages/Profile/Profile'));
const PlayerCards = React.lazy(() => import('./components/Pages/PlayerCards/PlayerCards'));
*/
const About = React.lazy(() => import('./components/Pages/About/About'));
const Career = React.lazy(() => import('./components/Pages/Career/Career'));
const Terms = React.lazy(() => import('./components/Pages/Terms/Terms'));
const PrivacyPolicy = React.lazy(() => import ('./components/Pages/PrivacyPolicy/PrivacyPolicy'));
const CommunityGuidelines = React.lazy(() => import('./components/Pages/CommunityGuidelines/CommunityGuidelines'));
const RefundPolicy = React.lazy(() => import('./components/Pages/RefundPolicy/RefundPolicy'));
const FAQ = React.lazy(() => import('./components/Pages/FAQ/FAQ'));



/* HCL - START */
const HCL_Home = React.lazy(() => import('./components/Pages/LiveOps/HCL/Home/Home'));
const HCL_Cities = React.lazy(() => import('./components/Pages/LiveOps/HCL/Cities/Cities'));
const HCL_Schedule = React.lazy(() => import('./components/Pages/LiveOps/HCL/Schedule/Schedule'));
const HCL_Team = React.lazy(() => import('./components/Pages/LiveOps/HCL/Team/Team'));
const HCL_Guidelines = React.lazy(() => import('./components/Pages/LiveOps/HCL/Guidelines/Guidelines'));
/* HCL - END */


/* YC - START */
const YC_Home = React.lazy(() => import('./components/Pages/LiveOps/YC/Home/Home'));
const YC_Schedule = React.lazy(() => import('./components/Pages/LiveOps/YC/Schedule/Schedule'));
const YC_Guidelines = React.lazy(() => import('./components/Pages/LiveOps/YC/Guidelines/Guidelines'));
const YC_TopPlayers = React.lazy(() => import('./components/Pages/LiveOps/YC/TopPlayers/TopPlayers'));
/* YC - END */

/* FAQ Pages - START */
const AccountFAQ_Page = React.lazy(() => import('./components/Pages/FAQ_Pages/AccountFAQ_Page'))
const PaymentsFAQ_Page = React.lazy(() => import('./components/Pages/FAQ_Pages/PaymentsFAQ_Page'))
const RewardsFAQ_Page = React.lazy(() => import('./components/Pages/FAQ_Pages/RewardsFAQ_Page'))
const GameplayFAQ_Page = React.lazy(() => import('./components/Pages/FAQ_Pages/GameplayFAQ_Page'))
const OthersFAQ_Page = React.lazy(() => import('./components/Pages/FAQ_Pages/OthersFAQ_Page'))
/* FAQ Pages - END */


const ModelView = React.lazy(() => import('./components/Pages/ModelView/ModelView'));


const IGDC = React.lazy(() => import('./components/Pages/IGDC/IGDC'));


function Loader() {

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("loading_screen"),
      animationData: cricketAnim,
      renderer: "svg",
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid"
      }
    })
  }, []);

  return (
    <div className='row'>
      <div className='col-12 p-0'>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'
          style={{ minHeight: '100vh', backgroundColor: 'rgb(25, 6, 46)' }}>
            <section id="loading_screen" style={{ maxWidth: '128px', maxHeight: '128px' }}/>
        </div>
      </div>
    </div>
  );
}


function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const device = useMemo(() => isMobile ? "mobile": "desktop", [isMobile]);
  const Redirect = props => {
    const navigate = useNavigate()
    useEffect(() => {
      window.location.replace(props.href)
    }, [])
    return null;
  }

  return (
    <div className="App" style={{ fontFamily: 'Montserrat', overflow: "hidden" }}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/" element={<Home />} />
            <Route path="/career" element={
                  <Redirect href="https://towering-hyena-edc.notion.site/Work-at-Hitwicket-6ef09b734cc24e2f8486185f9637d0ac" />
              } />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/community-guidelines" element={<CommunityGuidelines />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/faq" exact element={<FAQ />} />
            {/*
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/link-wallet" element={<LinkWallet />} />
              <Route path="/modelview" element={<ModelView />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/itemview" element={<ItemView />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/player-card" element={<PlayerCards />} />
            */}
            <Route path="/modelview" element={<ModelView />} />

            {/* HCL - START */}
            <Route path="/hcl" element={<HCL_Home />} />
            <Route path="/hcl-cities" element={<HCL_Cities />} />
            <Route path="/hcl-schedule" element={<HCL_Schedule />} />
            <Route path="/hcl-team" element={<HCL_Team />} />
            <Route path="/hcl-guidelines" element={<HCL_Guidelines />} />
            {/* HCL - END */}

            <Route path='/igdc' element={<IGDC />} />

            {/* YC - START */}
            <Route path="/yc" element={<YC_Home />} />
            <Route path="/yc-schedule" element={<YC_Schedule />} />
            <Route path="/yc-top-players" element={<YC_TopPlayers />} />
            <Route path="/yc-guidelines" element={<YC_Guidelines />} />
            {/* YC - END */}

            {/* FAQ Pages - START */}
            <Route path="/account-faq" element={<AccountFAQ_Page />} />
            <Route path="/payments-faq" element={<PaymentsFAQ_Page />} />
            <Route path="/rewards-faq" element={<RewardsFAQ_Page />} />
            <Route path="/gameplay-faq" element={<GameplayFAQ_Page />} />
            <Route path="/others-faq" element={<OthersFAQ_Page />} />
            {/* FAQ Pages - END */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

